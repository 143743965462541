import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="8c9e5d68fd0150a87176f65885e150f40ca144d5"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/la-diarrhee/"> En savoir plus sur la diarrhée </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils pour le traitement des symptomes de la diarrhée
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/causes-de-la-diarrhee/">
                  Causes de la diarrhée
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/stress-et-diarrhee/">
                  Stress et diarrhée
                </a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/emploi-du-temps-charge/">
                      Emploi du temps chargé
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/diarrhee-examens/">
                      Faire face aux examens
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/fr/la-diarrhee/infection-virale-ou-bacterienne/">
                  Infection virale ou bactérienne
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/la-diarrhee-pendant-les-regles/">
                  La diarrhée pendant les règles
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/la-diarrhee-chronique/">
                  La diarrhée chronique
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/consultez-votre-medecin/">
                  Consulter un médecin?
                </a>
              </li>
              <li className="active last">
                <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/">
                  Des habitudes alimentaires saines
                </a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/allergies-alimentaires/">
                      Allergies alimentaires{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/intoxication-alimentaire/">
                      Intoxication alimentaire
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/recettes-sante/">
                      Recettes Santé
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Recettes</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_rezepte.jpeg"
                    width="170"
                    height="102"
                    alt="Recettes"
                  />
                </div>
                <p>
                  Une alimentation équilibrée est une condition préalable pour
                  la santé et le bien-être. Vous trouverez ici des recettes
                  déicieuses et saines.
                </p>
                <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/recettes-sante/">
                  Plus d'info
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-16">
            <h1>Des habitudes alimentaires saines</h1>
            <div className="img">
              <img
                src="/assets/files/pages/eetgewoonten.jpeg"
                width="701"
                height="289"
                alt="Des habitudes alimentaires saines"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Éviter la diarrhée</h2>
              </div>
              <div className="nine columns">
                <p>
                  La santé du système digestif est liée non seulement à ce qu’on
                  mange, mais aussi à la façon dont on le mange. C’est pourquoi
                  on peut souffrir de diarrhée et d’autres problèmes digestifs
                  même lorsque l’on mange sainement.
                </p>
              </div>
            </div>
            <h4>
              Voici quelques éléments importants auxquels vous devez veiller :
            </h4>
            <ul>
              <li>
                Il faut 20&nbsp;minutes à votre estomac pour signaler à votre
                cerveau qu’il est rempli&nbsp;; donc, si vous mangez plus
                lentement, vous pourrez manger moins et avoir moins d’aliments à
                digérer.
              </li>
              <li>
                Le fait d’avaler des aliments rapidement fait aussi avaler plus
                d’air, ce qui peut occasionner des ballonnements et affecter la
                digestion.
              </li>
              <li>
                Il est plus facile de digérer des petits repas légers que des
                gros repas lourds. Essayez donc de manger peu et souvent, avec
                des collations saines ( un fruit p.ex.) entretemps.
              </li>
              <li>
                Essayez aussi de prendre le dernier repas de la journée au moins
                3&nbsp;heures avant d’aller vous coucher, car le système
                digestif fonctionne plus lentement lorsque vous dormez. Cela
                peut même vous aider à mieux dormir. Si vous avez un petit creux
                au moment du coucher, mangez une banane. Evitez en tout cas un
                repas gras ou lourd.
              </li>
              <li>
                <a href="/fr/la-verite-sur-la-diarrhee/diagnostic-du-syndrome-du-colon-irritable/alimentation-en-cas-de-syndrome-du-colon-irritable/">
                  Évitez les excès d’aliments riches ou épicés
                </a>
                &nbsp;et ne buvez pas trop d’alcool.
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
